<template>
  <div>
    <PageTitle
      title="就诊记录"
      fixed
    ></PageTitle>
    <div style="position: relative; width: 85%; margin: 15px auto auto auto">
      <List :width="100">
        <p style="text-align: center; color: #3f9775">复诊开药</p>
        <ListItem :leftWidth="0" :rightWidth="100">
          <template #right>
            <div style="float: left; font-size: 12px; margin-top: 8px">
              门诊时间：
            </div>
            <!--<div class="borderColor" style="float: left;">-->
            <!--<p class="Encore" :style="KD">{{GetSJ(item.yysj)}}</p>-->
            <!--</div>-->
            <div class="borderColors" style="float: left">
              <van-field
                v-model="item.yysj"
                @click="calendarShow = true"
                readonly
                is-link
                arrow-direction="down"
                input-align="center"
                :style="KD"
                style="background-color: #f6f6f6"
              />
              <van-calendar
                v-model="calendarShow"
                @confirm="confirmDate"
                :show-confirm="false"
                :color="variables.green"
                :default-date="new Date(SJ)"
              />
            </div>
          </template>
          <template #bottom> </template>
        </ListItem>
        <ListItem
          :leftWidth="0"
          :rightWidth="100"
          style="overflow: scroll; max-height: 80px"
        >
          <template #right>
            <div @click="TZDZ">
              <div style="float: left; font-size: 12px; margin-top: 8px">
                我的地址：
              </div>
              <div
                class="borderColor"
                style="float: left"
                v-if="item.sjrsf != '来院自取'"
              >
                <p class="Encores" :style="KD">
                  {{ item.sjrxm }}{{ item.sjrhm }}
                </p>
                <p class="Encores" style="padding-top: 5px" :style="KD">
                  {{ item.sjrsf }} {{ item.sjrqs }} {{ item.sjrxj }}
                  {{ item.sjrzj }} {{ item.sjrxxdz }}
                </p>
                <div
                  style="
                    clear: both;
                    padding-right: 5px;
                    padding-top: 5px;
                    float: right;
                  "
                >
                  <van-icon name="edit" @click="TZDZ" />
                </div>
              </div>
              <div class="borderColor" style="float: left" v-else>
                <p class="Encores" :style="KD">来院自取</p>
                <div
                  style="
                    clear: both;
                    padding-right: 5px;
                    padding-top: 5px;
                    float: right;
                  "
                >
                  <van-icon name="edit" @click="TZDZ" />
                </div>
              </div>
            </div>
          </template>
          <template #bottom> </template>
        </ListItem>

        <ListItem :leftWidth="0" :rightWidth="100" :style="height">
          <template #right>
            <div style="clear: both" class="div-mt">
              <p>执业点：{{ item.ksmc }}</p>
              <p>开方医生：{{ item.ygxm }}</p>
              <div v-if="item.diagnosisList[0].jbmc != null">
                <p>诊断：</p>
                <div v-for="(v1, index) in item.diagnosisList" :key="index">
                  <div>
                    <p>{{ index + 1 }}.{{ v1.jbmc }}</p>
                  </div>
                </div>
              </div>
              <div v-else>
                <p>诊断：未开具</p>
              </div>
            </div>
            <p style="padding-top: 10px">药品名称：</p>
            <div
              class="div-mt"
              v-for="(v, index) in item.ypxx1List"
              :key="index"
            >
              <p>
                {{ index + 1 }}.{{ v.ypmc }} ({{ v.yfgg }}) 共{{ v.ypsl
                }}{{ v.yfdw }}
              </p>
              <p style="color: #76b49c; margin-left: 10px">
                用法：{{ v.cfbz }}，{{ v.xmmc }}
              </p>
            </div>
            <div class="div-mt width-half">
              <div><p>挂号费：</p></div>
              <div v-if="item.ghf != '暂无'">
                <p>￥{{ item.ghf }}元</p>
              </div>
              <div v-else>
                <p>{{ item.ghf }}</p>
              </div>
              <div><p>药品费用：</p></div>
              <div v-if="item.totalFee != '暂无'">
                <p>￥{{ item.totalFee }}元</p>
              </div>
              <div v-else>
                <p>{{ item.totalFee }}</p>
              </div>
              <div><p>药师审核费：</p></div>
              <div>
                <p>{{ item.ysshf }}</p>
              </div>
              <div><p>快递费（顺丰快递）：</p></div>
              <div>
                <p>{{ item.kdf }}</p>
              </div>
            </div>
            <div style="float: right">
              <p style="font-size: 14px; padding-right: 10px">
                合计费用：￥{{ item.hjfy }}元
              </p>
            </div>
          </template>
          <template #bottom> </template>
        </ListItem>
      </List>
    </div>
    <van-button
      type="primary"
      block
      @click="submit()"
      style="
        width: 85%;
        bottom: 0;
        position: fixed;
        margin-left: 7.5%;
        margin-bottom: 10px;
      "
      >提交</van-button
    >
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'
import List from '@/components/home-page/List.vue'
import ListItem from '@/components/home-page/ListItem.vue'
import { GetJLXQ, CopyApplyInfo, IsReservable } from '@/api/check.js'
import { checkApplyRepeat } from '@/api/global.js'
import { getDefaultAccount } from '@/api/user.js'
import variables from '@/style/var.less'
import { Dialog } from 'vant'

export default {
  components: {
    PageTitle,
    List,
    ListItem,
  },

  data () {
    return {
      variables: variables,
      item: {
        diagnosisList: [
          {}
        ]
      },
      cardID: "",
      height: {
        height: '',
        overflow: 'scroll'
      },
      sjrxm: '',
      tcs: false,
      KD: {
        width: '',
      },
      calendarShow: false,
      SJ: '',
      cardInfo: '',
      brxx: '',
      ckhm: '',
      pd: '',
      ddbm: '',
    }
  },
  methods: {
    hh () {
      this.pd = this.$route.params.pd
      this.KD.width = (document.documentElement.clientWidth) * 0.85 - 130 + 'px'
      this.cardID = this.$route.params.jzxh
      this.sjrxm = this.$route.params.xm
      this.brxx = this.$route.params.brxx
      this.height.height = document.documentElement.clientHeight - 380 + 'px'
      getDefaultAccount(this.$store.getters.userInfo.UserId).then(
        (result) => {
          this.ckhm = result.ckhm
        },
        () => { }
      )
      if (this.sjrxm != "" && this.sjrxm != null) {
        GetJLXQ(this.cardID, 1).then(
          (result) => {
            this.item = result
            this.item.sjrxm = this.$route.params.xm
            this.item.sjrhm = this.$route.params.phone
            //                            this.item.sflyzq=this.$route.params.sflyzq;
            this.item.sjrsf = this.$route.params.dz1
            this.item.sjrqs = this.$route.params.dz2
            this.item.sjrxj = this.$route.params.dz3
            this.item.sjrzj = this.$route.params.dz4
            this.item.sjrxxdz = this.$route.params.dz5
            var time = JSON.parse(localStorage.getItem('Time'))

            //                            this.SJ= this.$util.moment().format('YYYY-MM-DDTHH:mm:ss')
            //                            this.item.yysj=this.GetSJ(this.item.yysj);
            this.SJ = time.sj
            this.item.yysj = time.yysj
          },
          () => {
          }
        )
        getDefaultAccount(this.$store.getters.userInfo.UserId).then(
          (data) => {
            this.cardInfo = data
            this.$store.dispatch('pageData/setInfo', data)
            if (this.cardInfo.zhyxpb == '0') {
              this.$dialog.alert({
                title: '温馨提醒',
                message: '此卡已失效，请更换卡号',
              }).then(() => { })
            }
          }, () => {

          })
      }
      else {

        GetJLXQ(this.cardID, 1).then(
          (result) => {
            this.item = result
            this.SJ = this.$util.moment().format('YYYY-MM-DDTHH:mm:ss')
            this.item.yysj = this.GetSJ(this.item.yysj)
            if (this.item.sflyzq == '来院自取') {
              this.item.sjrsf = '来院自取'
            }
            if (this.brxx == "1") {
              var brxx = JSON.parse(localStorage.getItem('encoreParams'))

              this.item.sjrxm = brxx.xm
              this.item.sjrhm = brxx.phone
              //                             this.item.sflyzq=this.$route.params.sflyzq;
              this.item.sjrsf = brxx.dz1
              this.item.sjrqs = brxx.dz2
              this.item.sjrxj = brxx.dz3
              this.item.sjrzj = brxx.dz4
              this.item.sjrxxdz = brxx.dz5
              this.SJ = brxx.sj
              this.item.yysj = brxx.yysj

            }
            if (this.brxx == "-1") {
              var time = JSON.parse(localStorage.getItem('Time'))
              //                            this.SJ= this.$util.moment().format('YYYY-MM-DDTHH:mm:ss')
              //                            this.item.yysj=this.GetSJ(this.item.yysj);
              this.SJ = time.sj
              this.item.yysj = time.yysj
            }
          },
          () => {
          }
        )
        getDefaultAccount(this.$store.getters.userInfo.UserId).then(
          (data) => {
            this.cardInfo = data
            this.$store.dispatch('pageData/setInfo', data)
            if (this.cardInfo.zhyxpb == '0') {
              this.$dialog.alert({
                title: '温馨提醒',
                message: '此卡已失效，请更换卡号',
              }).then(() => { })
            }
          }, () => {

          })

      }
    },

    confirmDate (value) {

      this.$set(this.item, 'yysj', this.$util.moment(value).format('MM月DD日'))
      this.SJ = this.$util.moment(value).format('YYYY-MM-DDTHH:mm:ss')
      //                checkApplyRepeat( this.SJ, this.item.ddbm).then(
      //                    (result) => {
      ////                        console.log(result);
      //                        if (result.data.msg != '成功') {
      //                            Dialog.alert({
      //                                message: result.data.msg,
      //                            }).then(() => {
      //                                // on close
      ////                            this.$router.push('/visit');
      //                            });
      //                        }
      //                    }
      //                );
      this.calendarShow = false
    },
    TZDZ () {
      this.$router.push('/address/-1/' + this.cardID)
      // on cancel
      let time = {
        sj: this.SJ,
        yysj: this.item.yysj,
      }

      localStorage.setItem('Time', JSON.stringify(time))//支付完跳回来的route附带参数
    },
    GetZLYD () {
      //通过push进行跳转
      let TJ = ""
      if (this.item.sjrsf != '来院自取') {
        TJ = {
          "sjr_Name": this.item.sjrxm,
          "sjr_Phone": this.item.sjrhm,
          "sjr_Province": this.item.sjrsf,
          "sjr_City": this.item.sjrqs,
          "sjr_Area": this.item.sjrxj,
          "sjr_Street": this.item.sjrzj,
          "sjr_SpecificAddress": this.item.sjrxxdz,
          "appointTime": this.SJ,
          "cardId": this.ckhm,
        }
      }
      else {
        TJ = {
          "appointTime": this.SJ,
          "sjr_Name": '来院自取',
          "cardId": this.ckhm,
        }
      }
      CopyApplyInfo(this.item.ddbm, TJ).then(
        () => {
          Dialog.alert({
            message: '提交成功',
          }).then(() => {
            // on close
            this.$router.push('/visit')
          })
        },
      )

    },
    GetSJ () {

      return this.$util.moment().format("MM月DD日")
    },
    PD (e) {
      if (e == null) {
        return ""
      }
      else {
        return e
      }
    },
    SJJX (e) {
      //                console.log(e);
      //                console.log(this.$util.moment(e).format("YYYY MM DD"))
      return this.$util.moment(e).format("YYYY MM DD")
    },
    submit () {
      if (this.cardInfo.zhyxpb == '0') {
        this.$dialog.alert({
          title: '温馨提醒',
          message: '此卡已失效，请更换卡号',
        }).then(() => { })
        return
      }
      function submitJudge (vue) {
        if (vue.cardInfo.zhye < 800) {
          vue.$dialog.confirm({
            title: '温馨提醒',
            message: '您当前账户预存金额为' + vue.cardInfo.zhye + '元\n为了您的云申请单顺利处理\n请保持账户预存金额大于800元\n（此金额随时可以退回）',
            confirmButtonText: '跳过',
            cancelButtonText: '充值'
          }).then(() => {
            // on confirm
            vue.GetZLYD()
            //                            vue.GetZLYD();
          }).catch(() => {
            // on cancel
            let BRXX = {
              dz1: vue.item.sjrsf,
              dz2: vue.item.sjrqs,
              dz3: vue.item.sjrxj,
              dz4: vue.item.sjrzj,
              dz5: vue.item.sjrxxdz,
              xm: vue.item.sjrxm,
              phone: vue.item.sjrhm,
              sj: vue.SJ,
              yysj: vue.item.yysj,
            }
            localStorage.setItem('encoreParams', JSON.stringify(BRXX))//支付完跳回来的route附带参数
            localStorage.setItem('payDoctorId', vue.cardID)//就诊卡号
            localStorage.setItem('payCardId', vue.ckhm)//就诊卡号
            localStorage.setItem('payPreRoute', 'encore')
            vue.$router.push('/recharge/1')
          })
        } else {
          vue.GetZLYD()
        }
      }
      IsReservable(this.item.ddbm).then(res => {
        console.log(res)
        checkApplyRepeat(this.SJ, this.item.ddbm).then((result) => {
          if (result.length) {
            this.$dialog.confirm({
              title: '温馨提醒',
              message: result[0],
              confirmButtonText: '修改时间',
              cancelButtonText: '继续'
            }).then(() => {
              // on confirm
              this.calendarShow = true
            }).catch(() => {
              // on cancel
              //判断金额
              submitJudge(this)
            })
            return
          }
          submitJudge(this)
        }, () => { })
      })
    }
  },
  created: function () {
    // init sendMsg object
    this.hh()
  },

}
</script>

<style scoped="" lang="less">
@import "../style/var.less";

.div-mt {
  margin-top: 10px;
}
.width-half {
  div {
    width: 50%;
    display: inline-block;
  }
  div:nth-child(2n) p {
    float: right;
    padding-right: 10px;
    color: #ff2600;
  }
  div:nth-child(2n + 1) p {
    float: left;
  }
}
.infoContainer {
  padding: 10px;

  p {
    margin: 0;
  }
  .top p {
    text-align: center;
    margin: 0 0 10px;
  }
  .right {
    p {
      font-size: @font-size-sm;
    }
  }

  p {
    margin: 0;
  }
  .borderColors {
    background-color: #f6f6f6;
    border-radius: 8px;
    padding-left: 10px;
    margin-left: 10px;
    padding-right: 10px;
    .leftArea {
      width: 85%;
      display: inline-block;
    }
    .rightArea {
      width: 15%;
      display: inline-block;
      vertical-align: top;
      p {
        float: right;
      }
      .van-icon {
        float: right;
        margin-top: 40px;
      }
    }
  }
  .fontmd {
    font-size: @font-size-md;
  }
  .fontsm {
    font-size: @font-size-sm;
  }

  .borderColor {
    background-color: #f6f6f6;
    border-radius: 8px;
    padding-left: 10px;
    padding-top: 10px;
    margin-left: 10px;
    padding-right: 10px;
    .leftArea {
      width: 85%;
      display: inline-block;
    }
    .rightArea {
      width: 15%;
      display: inline-block;
      vertical-align: top;
      p {
        float: right;
      }
      .van-icon {
        float: right;
        margin-top: 40px;
      }
    }
  }
}
.Encore {
  width: 180px;
  height: 10px;
  background: #f6f6f6;
  border-radius: 8px;
  text-align: center;
}
.Encores {
  width: 180px;

  background: #f6f6f6;
  border-radius: 8px;
}
</style>